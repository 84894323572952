import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";

import PageHeading from "../../../Components/PageHeading/PageHeading";
import './RelationshipsPage.css'
import Checklist from "../../../Components/Checklist/Checklist";

const RelationshipsPage = () => {
    const location = useLocation();

    useEffect(() => {
        // Scroll logic here, triggered on component mount or when the location changes
        const { hash } = location;
        const element = document.getElementById(hash.substring(1));
        if (hash) {
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    let pageDetails = {
        imgClass: "relationshipsheading-bg-img",
        heading: "Welcome to White Flag Relationship Coaching!",
        bgColors: {
            // Blue and Yellow - Personal Coaching
            // primary: "#EED570",
            // secondary: "#377ed4"

            // Purple and Pink - Relationships
            primary: "#F57697",
            secondary: "#7651e1"

            // Orange and Green - Groups
            // primary: "#F68351",
            // secondary: "#377c70"
        },
        dropshadow: "relationships-dropshadow",
        paragraphs: {
            p1: "We understand seeking help for a struggling relationship is tough. But you're brave to take this step! Relationships, romantic, family, friendships - they all need work sometimes. That's where we come in.",
            p2: "Our experienced coaches (5+ years, 650+ clients) offer confidential, personalized support to guide you towards a healthier, happier connection.  Let's work together to strengthen your bond."
        },
        list_content: {}
    }

    const checklist_items = [
        {
            heading: "Little or no communication",
            content: "Lack of conversation or engagement between each other."
        },
        {
            heading: "Unresolved conflict",
            content: "Internalized problems between you that haven't been resolved or completely addressed."
        },
        {
            heading: "Feeling disconnected",
            content: "One or both of you don't have or have lost the close connection you once had."
        },
        {
            heading: "Constant arguments",
            content: "Arguments start and never seem to stop, regardless of their relevance."
        },
        {
            heading: "Emotionally or mentally drained",
            content: "The relationship is having a draining effect on both partners."
        },
        {
            heading: "Growth and development conflict",
            content: "As the relationship becomes older and more serious (e.g., marriage), new differences emerge that create conflict."
        },
        {
            heading: "Past trauma effects on relationship",
            content: "Previously experienced and unresolved trauma negatively impacts the current relationship."
        },
        {
            heading: "Need for debrief",
            content: "The need for a safe, unbiased, and non-judgmental space to be heard and express oneself freely."
        }
    ];

    return (
        <React.Fragment>
            <PageHeading PageInfo={pageDetails}>
                <h4 className={"w-100 mx-auto"} style={{marginBottom: "-50px", marginTop: "20px", color: "white"}}>
                    Choose from the list below in order for us to have an idea of what your coaching journey would need to focused on. On receipt of your symptoms and conditions that is sent via email to us we would set up a free intro call (15min ) to discuss the way forward . Lets go!
                </h4>
                <Checklist ChecklistItems={checklist_items} Heading={"Symptoms and conditions you may be experiencing"}/>
            </PageHeading>
        </React.Fragment>
    );
}

export default RelationshipsPage;