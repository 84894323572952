import React from "react";
import { Link } from "react-router-dom";
import './SocialMediaNavbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCircleInfo
} from '@fortawesome/free-solid-svg-icons';
import {
    faWhatsapp,
    faFacebookF,
    faLinkedin,
    faTwitter,
    faInstagram,
    faTiktok
} from '@fortawesome/free-brands-svg-icons';

const socialLinks = [
    {
        name: 'WhatsApp',
        icon: faWhatsapp,
        href: "https://wa.me/message/SMAJWV7O2KFNO1",
        isWhatsApp: true
    },
    {
        name: 'Facebook',
        icon: faFacebookF,
        href: "https://www.facebook.com/whiteflagcoaching"
    },
    {
        name: 'LinkedIn',
        icon: faLinkedin,
        href: "https://www.linkedin.com/in/white-flag-coaching-npc-b33b291b3/?originalSubdomain=za"
    },
    {
        name: 'Twitter',
        icon: faTwitter,
        href: "https://twitter.com/whiteflagcoach"
    },
    {
        name: 'Instagram',
        icon: faInstagram,
        href: "https://www.instagram.com/whiteflag_coaching/"
    },
    {
        name: 'TikTok',
        icon: faTiktok,
        href: "https://www.tiktok.com/@whiteflagcoaching"
    },
    {
        name: 'FAQ',
        icon: faCircleInfo,
        href: "/faq",
        isInternalLink: true
    }
];

const SocialMediaNavbar = ({className}) => {
    const handleOnclick = (href) => {
        window.location.href = href;
    };

    return (
        <nav className={`social ${className}`}>
            <ul className="social-list">
                {socialLinks.map((link) => (
                    <li key={`${link.name}-${link.href}`}>
                        {link.isWhatsApp ? (
                            <span onClick={() => handleOnclick(link.href)}>
                                <FontAwesomeIcon icon={link.icon} />
                                <div className="whatsappQR"></div>
                            </span>
                        ) : link.isInternalLink ? (
                            <Link to={link.href}>
                                <FontAwesomeIcon icon={link.icon} />
                            </Link>
                        ) : (
                            <a href={link.href} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={link.icon} />
                            </a>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default SocialMediaNavbar;