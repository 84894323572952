// src/utils/FontLoader.js
export class FontLoader {
    static async load() {
        if ('fonts' in document) {
            try {
                const fontWeights = [
                    { weight: 300, name: 'Light' },
                    { weight: 400, name: 'Book' },
                    { weight: 500, name: 'Medium' },
                    { weight: 600, name: 'Demi' },
                    { weight: 700, name: 'Bold' },
                    { weight: 800, name: 'ExtraBold' },
                    { weight: 900, name: 'Heavy' }
                ];

                const fontPromises = fontWeights.map(({ weight }) => 
                    document.fonts.load(`${weight} 1em "Futura PT"`)
                );

                await Promise.all(fontPromises);
                console.log('Fonts loaded successfully');
                return true;
            } catch (error) {
                console.error('Font loading failed:', error);
                // Fallback to system fonts
                document.documentElement.style.setProperty(
                    '--font-primary',
                    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif'
                );
                return false;
            }
        }
        return false;
    }
}