import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import './Contact.css'
import axios from "axios";
import Input from "./Inputs/Input";
import {
    VALIDATOR_REQUIRE,
    VALIDATOR_EMAIL
} from '../util/validators';
import { useForm } from "../hooks/form-hook";
import logger from '../util/logger';

require('dotenv').config()

const coachingOptions = [
    {
        value: "Individual",
        label: "Individual Coaching",
        description: "One-on-one personalized coaching sessions",
        icon: "👤"
    },
    {
        value: "Relationships",
        label: "Relationship Coaching",
        description: "Support for couples and relationship dynamics",
        icon: "❤️"
    },
    {
        value: "Groups",
        label: "Group Coaching",
        description: "Interactive sessions for small groups",
        icon: "👥"
    },
    {
        value: "Businesses",
        label: "Business Coaching",
        description: "Professional development for organizations",
        icon: "💼"
    }
];

const contactTimes = [
    "Morning (9AM - 12PM)",
    "Afternoon (12PM - 5PM)",
    "Evening (5PM - 8PM)"
];

const referralSources = [
    "Google Search",
    "Social Media",
    "Friend/Family Referral",
    "Professional Referral",
    "Other"
];

const ContactPage = ({ selectedItems }) => {
    const contactFormRef = useRef(null);
    const [selectedRole, setSelectedRole] = useState('Individual');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const [formState, inputHandler, setFormData] = useForm(
        {
            name: {
                value: '',
                isValid: false
            },
            emailAddress: {
                value: '',
                isValid: false
            },
            phoneNumber: {
                value: '',
                isValid: true  // Make phone optional
            },
            preferredContact: {
                value: '',
                isValid: false
            },
            contactTime: {
                value: '',
                isValid: false
            },
            referralSource: {
                value: '',
                isValid: false
            },
            formMessage: {
                value: '',
                isValid: true  // Make message optional
            },
            coachingSessions: {
                value: selectedRole,
                isValid: true  // Since it has a default value
            },
            selectedItems: {
                value: selectedItems,
                isValid: true
            },
            privacyConsent: {
                value: false,
                isValid: false
            }
        },
        false
    );

    // Add this after your form state initialization
    useEffect(() => {
        console.log('Form State Changed:', {
            isValid: formState.isValid,
            inputs: Object.entries(formState.inputs).reduce((acc, [key, value]) => {
                acc[key] = { value: value.value, isValid: value.isValid };
                return acc;
            }, {})
        });
    }, [formState]);

    const today = new Date();
    const formatDate = (date) => {
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();
        return `${dd}-${mm}-${yyyy}`;
    };

    const formattedDate = formatDate(today);

    const formatEmailBody = (formState) => {
        try {
            // Validate required inputs
            if (!formState?.inputs?.name?.value) {
                throw new Error('Name is required but not provided');
            }

            // Safely handle symptoms/selected items
            let symptomsMessage;
            try {
                const selectedItems = formState.inputs.selectedItems?.value;

                // Check if selectedItems exists and is an array
                if (!selectedItems) {
                    symptomsMessage = 'No symptoms data available';
                } else if (!Array.isArray(selectedItems)) {
                    symptomsMessage = `Invalid symptoms data format: ${typeof selectedItems}`;
                    logger.warn('Invalid symptoms data format:', { selectedItems });
                } else {
                    symptomsMessage = selectedItems.length > 0
                        ? selectedItems
                            .filter(item => item && typeof item === 'string')
                            .map(item => `- ${item.trim()}\n\t   `)
                            .join('')
                        : `No symptoms selected by ${formState.inputs.name.value}`;
                }
            } catch (err) {
                logger.error('Error formatting symptoms:', err);
                symptomsMessage = 'Error processing symptoms data';
            }

            const emailMessage = formState.inputs.formMessage?.value?.trim?.() || `No message entered by ${formState.inputs.name.value}`;

            return `
                Hi White Flag, I trust you well.
                
                Please reach out to me.
                
                See the below information:
                
                Name: ${formState.inputs.name.value}
                Email: ${formState.inputs.emailAddress.value}
                Phone Number: ${formState.inputs.phoneNumber.value}
                Preferred Contact Method: ${formState.inputs.preferredContact.value}
                Best Time to Contact: ${formState.inputs.contactTime.value}
                How they found us: ${formState.inputs.referralSource.value}
                
                Coaching sessions are for: ${formState.inputs.coachingSessions.value}
                
                Additional Information:
                
                ${emailMessage}
                
                Symptoms and Conditions:
                ${symptomsMessage}
            `;
        } catch (error) {
            logger.error('Error formatting email body:', error);
            throw new Error('Failed to format email content. Please check your form data.');
        }
    };

    // In your handleSubmit function, add better error handling:
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            // Validate privacy consent
            if (!formState.inputs.privacyConsent.value) {
                setError('Please accept the privacy policy to continue');
                return;
            }

            // Validate required fields
            const requiredFields = ['name', 'emailAddress', 'preferredContact', 'contactTime', 'coachingSessions', 'referralSource'];
            const missingFields = requiredFields.filter(field => !formState.inputs[field]?.value);

            if (missingFields.length > 0) {
                setError(`Please fill in all required fields: ${missingFields.join(', ')}`);
                return;
            }

            setIsLoading(true);
            setError(null);
            setSuccess(null);

            const emailBody = formatEmailBody(formState);

            console.log(`Reaching backend server on: ${process.env.REACT_SERVER_BACKEND_URL || "http://localhost:3001"}/api/send`)

            const response = await axios.post(`${process.env.REACT_SERVER_BACKEND_URL || "http://192.168.10.141:3001"}/api/send`, {
                subject: `Contact form - White Flag Coaching - ${formattedDate}`,
                message: emailBody
            });

            setSuccess(response.data.message);

            // Reset form
            setSelectedRole('Individual');
            setFormData(
                {
                    name: { value: '', isValid: false },
                    emailAddress: { value: '', isValid: false },
                    phoneNumber: { value: '', isValid: false },
                    preferredContact: { value: '', isValid: false },
                    contactTime: { value: '', isValid: false },
                    referralSource: { value: '', isValid: false },
                    formMessage: { value: '', isValid: true },
                    coachingSessions: { value: selectedRole, isValid: false },
                    selectedItems: { value: selectedItems, isValid: true },
                    privacyConsent: { value: false, isValid: false }
                },
                false
            );

        } catch (error) {
            logger.error('Form submission error:', error);

            // Handle different types of errors
            if (error.response) {
                // Server responded with an error
                setError(`Server error: ${error.response.data.message || 'Please try again later'}`);
            } else if (error.request) {
                // Request made but no response
                setError('Unable to reach the server. Please check your internet connection.');
            } else {
                // Error in request setup
                setError(error.message || 'Error sending email. Please try again later.');
            }
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <div className="contact-page-container align-middle">
            <form ref={contactFormRef} onSubmit={handleSubmit} method="post">
                <div className="center-contact-form">
                    <div className="row">
                        <h1>Contact Us</h1>
                    </div>
                    <div className="row">
                        <h4>We'd love to hear from you!</h4>
                    </div>
                    <div className="row">
                        {error && <div className="error" role="alert">{error}</div>}
                        {success && <div className="success" role="status">{success}</div>}
                    </div>
                    <div className="row input-container">
                        <div className="col-xs-12">
                            <Input
                                type="text"
                                element="text"
                                className="styled-input wide"
                                label="Full Name"
                                id="name"
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="Please enter your full name."
                                onInput={inputHandler}
                                aria-required="true"
                            />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input
                                element="text"
                                className="styled-input"
                                label="Email Address"
                                id="emailAddress"
                                validators={[VALIDATOR_EMAIL()]}
                                errorText="Please enter a valid email address."
                                onInput={inputHandler}
                                aria-required="true"
                            />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input
                                element="text"
                                className="styled-input"
                                label="Phone Number"
                                id="phoneNumber"
                                validators={[]}
                                errorText="Please enter a valid phone number."
                                onInput={inputHandler}
                                aria-required="true"
                            />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input
                                element="select"
                                className="styled-input"
                                label="Preferred Contact Method"
                                id="preferredContact"
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="Please select your preferred contact method."
                                onInput={inputHandler}
                                listItems={["Email", "Phone", "Either"]}
                                aria-required="true"
                            />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input
                                element="select"
                                className="styled-input"
                                label="Best Time to Contact"
                                id="contactTime"
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="Please select your preferred contact time."
                                onInput={inputHandler}
                                listItems={contactTimes}
                                aria-required="true"
                            />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input
                                element="select"
                                className="styled-input"
                                listItems={coachingOptions.map(option => `${option.icon} ${option.label}`)}
                                label="What coaching sessions are you interested in?"
                                id="coachingSessions"
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="Please select a coaching type."
                                onInput={inputHandler}
                                aria-required="true"
                            />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Input
                                element="select"
                                className="styled-input"
                                label="How did you hear about us?"
                                id="referralSource"
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="Please select how you found us."
                                onInput={inputHandler}
                                listItems={referralSources}
                                aria-required="true"
                            />
                        </div>
                        <div className="col-xs-12">
                            <Input
                                element="textarea"
                                className="styled-input wide"
                                label="Message"
                                id="formMessage"
                                validators={[]}
                                errorText=""
                                onInput={inputHandler}
                                aria-required="false"
                            />
                        </div>
                        <div className="col-xs-12">
                            <div className="privacy-consent-container">
                                <Input
                                    element="checkbox"
                                    id="privacyConsent"
                                    validators={[VALIDATOR_REQUIRE()]}
                                    errorText="You must accept the privacy policy"
                                    onInput={inputHandler}
                                    aria-required="true"
                                />
                                <label htmlFor="privacyConsent" className="privacy-label">
                                    I agree to the <Link to="/privacy-policy">privacy policy</Link> and consent to being contacted about my inquiry.
                                </label>
                            </div>
                        </div>
                        <div className="col-xs-12">
                            <button
                                type="submit"
                                className="btn-lrg submit-btn"
                                disabled={!formState.isValid || isLoading}
                                aria-disabled={!formState.isValid || isLoading}
                            >
                                {isLoading ? 'Sending...' : 'Send Message'}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ContactPage;