import React, { useReducer, useEffect } from 'react';
import { validate } from '../../util/validators';
import './Input.css';

const inputReducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE':
            const isCheckbox = typeof action.val === 'boolean';
            return {
                ...state,
                value: action.val,
                isValid: isCheckbox ? action.val : validate(action.val, action.validators)
            };
        case 'TOUCH':
            return {
                ...state,
                isTouched: true
            };
        default:
            return state;
    }
};

const Input = props => {
    const listItems = props.listItems || [];
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: props.initialValue || '',
        isTouched: false,
        isValid: props.initialValue || false
    });

    const { id, onInput } = props;
    const { value, isValid } = inputState;

    useEffect(() => {
        onInput(id, value, isValid)
    }, [id, value, isValid, onInput]);

    // Handle change in the element for each keystroke
    const handleChange = event => {
        // Special handling for checkbox
        if (props.element === 'checkbox') {
            dispatch({
                type: 'CHANGE',
                val: event.target.checked,
                validators: props.validators
            });
        } else {
            dispatch({
                type: 'CHANGE',
                val: event.target.value,
                validators: props.validators
            });
        }
    };

    const handleBlur = () => {
        dispatch({
            type: 'TOUCH'
        });
    };

    let element;
    switch (props.element) {
        case 'checkbox':
            element = (
                <div className="checkbox-container">
                    <input
                        id={props.id}
                        type="checkbox"
                        className="checkbox"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={inputState.value}
                    />
                </div>
            );
            break;
        case 'text':
            element = (
                <>
                    <input
                        id={props.id}
                        type="text"
                        className="input"
                        placeholder={props.placeholder}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={inputState.value}
                    />
                    <label
                        htmlFor={props.id}
                        className={`label-text ${!inputState.isValid && inputState.isTouched && `error-text`}`}
                    >
                        {(!inputState.isValid && inputState.isTouched && props.errorText) || props.label}
                    </label>
                </>
            );
            break;
        case 'select':
            element = (
                <div className="select-container">
                    <label
                        htmlFor={props.id}
                        className={`dropdown-label  ${!inputState.isValid && inputState.isTouched && `error-text`}`}
                    >
                        {props.label}
                        {!inputState.isValid && inputState.isTouched && <span className="error-message">: {props.errorText}</span>}
                    </label>
                    <select
                        id={props.id}
                        className="input select-field"
                        value={inputState.value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        <option value="" disabled defaultValue={value}>Choose an option</option>
                        {listItems.map(item => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                </div>
            );
            break;
        case 'textarea':
            element = (
                <>
                    <textarea
                        id={props.id}
                        rows={props.rows || 3}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={inputState.value}
                    />
                    <label
                        htmlFor={props.id}
                        className={`label-text ${!inputState.isValid && inputState.isTouched && `error-text`}`}
                    >
                        {(!inputState.isValid && inputState.isTouched && props.errorText) || props.label}
                    </label>
                </>
            );
            break;
        default:
            element = (
                <input
                    id={props.id}
                    type={props.element}
                    className="input"
                    placeholder={props.placeholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={inputState.value}
                />
            );
    }

    return (
        <div
            // className={`form-control ${!inputState.isValid && inputState.isTouched &&
            className={`${props.className}`}
        >
            {element}
        </div>
    );
};

export default Input;