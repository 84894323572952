import React from "react";

import './Homepage.css'
import '../../OfferingPages/Businesses/EapPage.css'

import DynamicCards from "../../../Components/DynamicCards/DynamicCards";
import Contact from "../../../Components/ContactPage/Contact";
import Testimonial from "../../../Components/TestimonialSlider/HomePage/Testimonial";

const eapValues = [
    {
        className: 'home-eap-story',
        logo: 'History',
        heading: 'Cape Herbs and Spice',
        content: 'Lorem ipsum dolor sit amet consectetur. Ornare sed quisque vitae ultrices tellus arcu purus. Morbi orci quis sit duis vitae nunc. Nulla elementum viverra euismod massa mattis non dui leo faucibus. Et in pharetra ut pellentesque consectetur mattis.',
        link: 'https://www.youtube.com/embed/0NbUlatvt_c?si=_Xcf0G7l2sOmWlTK',
        linkName: 'Click Here.'
    },
    {
        className: 'home-eap-vision',
        logo: 'Visibility',
        heading: 'Our Vision',
        content: 'Lorem ipsum dolor sit amet consectetur. Ornare sed quisque vitae ultrices tellus arcu purus. Morbi orci quis sit duis vitae nunc. Nulla elementum viverra euismod massa mattis non dui leo faucibus. Et in pharetra ut pellentesque consectetur mattis.',
        link: 'https://www.youtube.com/embed/EHn0BkDSrHQ?si=qZgLBEH60MC0h-jh',
        linkName: 'Click Here.'
    }
]

const Homepage = () => {
    const vidRelativePath = `${process.env.PUBLIC_URL}/Assets/Videos/`

    return (
        <div id="body-wrapper" className="body-wrapper home">
            <section className="section section1">
                <div className="section-main-content video-container">
                    <video autoPlay loop muted>
                        <source src={vidRelativePath + "White Flag Video.mp4"} type="video/mp4" />
                    </video>
                </div>
            </section>
            <section className="section section2">
                <DynamicCards />
            </section>
            <section className="section">
                <Contact selectedItems={[]} />
            </section>
            <section className="home-eap-values">
                {eapValues.map((value, index) => (
                    <div key={index} className={value.className}>
                        <iframe
                            className="eap-values-video"
                            src={value.link}
                            title={`EAP Value Video ${index + 1}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                            loading="lazy" // Add lazy loading for better performance
                        />
                    </div>
                ))}
            </section>
            <section className="section">
                <Testimonial />
            </section>
        </div>
    )
};

export default Homepage;