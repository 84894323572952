import React from 'react';
import './Gallery.css';

const Gallery = () => {
    const imageHome = process.env.PUBLIC_URL + "/Assets/Images/CompanyLogos";

    const GALLERY_IMAGES = [
        {
            img: imageHome + "/condorgreen.png",
            heading: "Condorgreen",
            description: "Condorgreen"
        },
        {
            img: imageHome + "/cape_herb_and_spice.png",
            heading: "Cape Herb & Spice",
            description: "Cape Herb & Spice"
        },
        {
            img: imageHome + "/finchoice.png",
            heading: "Finchoice",
            description: "Finchoice"
        },
        {
            img: imageHome + "/seaside.jpg",
            heading: "Seaside Extended",
            description: "Seaside Extended"
        }
    ];

    return (
        <div className="gallery-body">
            <p className="gallery-heading">Partnerships</p>
            <div className="gallery-container">
                <div className="left-item">
                    <img src={GALLERY_IMAGES[0].img} alt={GALLERY_IMAGES[0].description} />
                </div>
                <div className="right-items">
                    {GALLERY_IMAGES.slice(1).map((image, index) => (
                        <div key={index} className="gallery-item">
                            <img src={image.img} alt={image.description} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Gallery;