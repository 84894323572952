import React from "react";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import './EAPTestimonial.css';
// import p from "express/lib/view";

const EAPTestimonial = () => {

    const imgRelativePath = `${process.env.PUBLIC_URL}/Assets/Images/CompanyLogos/`

    const testimonials = [
        {
            name: "Seaside Extended",
            testimonial: {
                p1: "We have been working with White Flag Coaching since 2022 and we have loved working with Sam and Mary during this time. They have been a great support to our leadership, as well as to our staff, especially on the back of COVID which presented many unforeseen pressures, especially around returning to the office (or hybrid working) often being a challenge after the pandemic. We love having them as an additional sounding board that can be trusted to work by our side.",
                p2: "Their coaching has not only helped with work-life balance and support, but also career guidance and coaching and achieving clarity on an often tricky landscape with many obstacles and opportunities. It's about getting the best out of one's self and keeping your cup full.",
                p3: "We look forward to working with them and highly recommend this service, something all corporates should consider with today's dynamic workforce and the economic and lifestyle pressures."
            },
            writer: "Veronique Bartie",
            role: 'Director',
            img: `${imgRelativePath}/seaside.jpg`
        },
        {
            name: "Seaside Extended",
            testimonial: {
                p1: "We have been working with White Flag Coaching since 2022 and we have loved working with Sam and Mary during this time. They have been a great support to our leadership, as well as to our staff, especially on the back of COVID which presented many unforeseen pressures, especially around returning to the office (or hybrid working) often being a challenge after the pandemic. We love having them as an additional sounding board that can be trusted to work by our side.",
                p2: "Their coaching has not only helped with work-life balance and support, but also career guidance and coaching and achieving clarity on an often tricky landscape with many obstacles and opportunities. It's about getting the best out of one's self and keeping your cup full.",
                p3: "We look forward to working with them and highly recommend this service, something all corporates should consider with today's dynamic workforce and the economic and lifestyle pressures."
            },
            writer: "Veronique Bartie",
            role: 'Director',
            img: `${imgRelativePath}/condorgreen.png`
        },
        {
            name: "Seaside Extended",
            testimonial: {
                p1: "We have been working with White Flag Coaching since 2022 and we have loved working with Sam and Mary during this time. They have been a great support to our leadership, as well as to our staff, especially on the back of COVID which presented many unforeseen pressures, especially around returning to the office (or hybrid working) often being a challenge after the pandemic. We love having them as an additional sounding board that can be trusted to work by our side.",
                p2: "Their coaching has not only helped with work-life balance and support, but also career guidance and coaching and achieving clarity on an often tricky landscape with many obstacles and opportunities. It's about getting the best out of one's self and keeping your cup full.",
                p3: "We look forward to working with them and highly recommend this service, something all corporates should consider with today's dynamic workforce and the economic and lifestyle pressures."
            },
            writer: "Veronique Bartie",
            role: 'Director',
            img: `${imgRelativePath}/condorgreen.png`
        }
    ]

    return (
        <div className="eap-testimonials-container">
            <h1 style={{marginBottom: "-10px", marginTop: "100px"}}>WHAT OUR PARTNERS ARE SAYING_</h1>
            <div className="eap-testimonials-row">
                <OwlCarousel 
                    id="home-customers-testimonials" 
                    loop={true} 
                    center={true} 
                    items={3} 
                    margin={0}
                    autoplay={true} 
                    dots={true} 
                    autoplayTimeout={8500} 
                    smartSpeed={450}
                    responsive={{
                        0: { items: 1 },
                        768: { items: 2 },
                        1170: { items: 3 },
                    }}
                    className="owl-carousel owl-theme"
                >
                    {testimonials.map((item, index) => (
                        <div key={`Testimonial Number: ${index}`} className="item eap-item">
                            <div className="home-shadow-effect">
                                <img 
                                    className="home-img-circle"
                                    src={item.img}
                                    alt={item.name} 
                                />
                                <div className="testimonial-content not-active">
                                    <div className="testimonial-text">
                                        {Object.values(item.testimonial).map((paragraph, pIndex) => (
                                            <p key={pIndex}>{paragraph}</p>
                                        ))}
                                    </div>
                                    {/* <div className="testimonial-author">
                                        <strong><i>{item.writer}</i></strong>
                                        <span> - </span>
                                        <b>{item.role}</b>
                                    </div> */}
                                </div>
                            </div>
                            <div className="home-testimonial-name not-active">{item.name}<h4 style={{color: "black"}}>{item.role} - {item.writer}</h4></div>
                            
                        </div>
                    ))}
                </OwlCarousel>
            </div>
        </div>
    );
};

export default EAPTestimonial;
