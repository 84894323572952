import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import SocialMediaNavbar from "../SocialMediaNavbar/SocialMediaNavbar";
import FAQs from "../SocialMediaNavbar/FAQs";
import './Navbar.css';

const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { pathname } = useLocation();
    const imgRelativePath = `${process.env.PUBLIC_URL}/Assets/Images`;

    const navItems = [
        { path: '/personal-coaching', label: 'Personal Coaching' },
        { path: '/relationships', label: 'Relationships' },
        { path: '/groups', label: 'Groups' },
        { path: '/eap-services', label: 'Businesses' },
        { path: '/', label: 'Home', className: 'ms-auto' },
        { path: '/faq', label: 'FAQ' },
        { path: '/contact-us', label: 'Contact Us' }
    ];
    
    useEffect(() => {
        if (pathname) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [pathname]);
    
    const handleClick = (path) => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
        
        if (window.innerWidth <= 1400) {
            setIsMenuOpen(false);
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav id="navbar" className={`navbar navbar-expand-xxl py-3 ${isScrolled ? 'scrolled' : ''}`}>
            <div className="container-fluid">
                <Link
                    className="navbar-brand"
                    to="/"
                    onClick={() => handleClick('/')}
                >
                    <img
                        src={`${imgRelativePath}/logo.png`}
                        width="50"
                        height="50"
                        alt="White Flag Coaching Logo"
                        loading="eager"
                    />
                    <span className="brand-text">White Flag Coaching</span>
                </Link>
                <button
                    className={`navbar-toggler ${isMenuOpen ? 'active' : ''}`}
                    type="button"
                    onClick={toggleMenu}
                    aria-controls="navbarSupportedContent"
                    aria-expanded={isMenuOpen}
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div
                    className={`collapse navbar-collapse navbar-scroll ${isMenuOpen ? 'show' : ''}`}
                    id="navbarSupportedContent"
                >
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {navItems.map((item) => (
                            <li
                                key={item.path}
                                className={`nav-item ${item.className || ''}`}
                            >
                                <Link
                                    to={item.path}
                                    onClick={() => handleClick(item.path)}
                                    className="nav-link"
                                >
                                    {item.label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <div className="navbar-extra">
                        {/* <FAQs className="show-socials hide-socials-nav greater" /> */}
                        <SocialMediaNavbar className="show-socials hide-socials-nav" />
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default React.memo(Navbar);