import React from "react";

import EAPTestimonial from "../../../Components/TestimonialSlider/EAP Page/EAPTestimonial";
import Gallery from '../../../Components/Gallery/Gallery';

import './EapPage.css';
// import ParticlesComponent from "../../../Components/ParticleJs/ParticlesComponent";

const eapValues = [
    {
        className: 'eap-story',
        logo: 'History',
        heading: 'Cape Herbs and Spice',
        content: 'Lorem ipsum dolor sit amet consectetur. Ornare sed quisque vitae ultrices tellus arcu purus. Morbi orci quis sit duis vitae nunc. Nulla elementum viverra euismod massa mattis non dui leo faucibus. Et in pharetra ut pellentesque consectetur mattis.',
        link: 'https://www.youtube.com/embed/b42B8IGmnew?si=vxXjjhyoJhV55bGl',
        linkName: 'Click Here.'
    },
    {
        className: 'eap-mission',
        logo: 'target',
        heading: 'Listen To Our Story',
        content: 'Jane shared how the Employee Assistance Program (EAP) helped her through a difficult time by providing confidential counseling and support. With their guidance, she managed her stress and found a better work-life balance, improving her overall well-being.',
        link: 'https://www.youtube.com/embed/307Iw_R8tFQ?si=gLxJ_wQEscIzemWG',
        linkName: 'Click Here.'
    },
    {
        className: 'eap-vision',
        logo: 'Visibility',
        heading: 'Our Vision',
        content: 'Lorem ipsum dolor sit amet consectetur. Ornare sed quisque vitae ultrices tellus arcu purus. Morbi orci quis sit duis vitae nunc. Nulla elementum viverra euismod massa mattis non dui leo faucibus. Et in pharetra ut pellentesque consectetur mattis.',
        link: 'https://www.youtube.com/embed/EHn0BkDSrHQ?si=qZgLBEH60MC0h-jh',
        linkName: 'Click Here.'
    }
]

const EapPage = () => {
    // Render PageHeading with pageDetails (including children)
    return (
        <div className="eap-body">
            <section className="eap-welcome-screen">
                <div className="eap-welcome-screen-content">
                    <h1>White Flag Coaching</h1>
                    <h2>EAP SERVICES</h2>
                    <p>At White Flag Coaching, we are dedicated to supporting your well-being. Our confidential services
                        include counselling, work-life balance support, wellness programs, and crisis intervention.
                        Explore how we can help you thrive both professionally and personally. Your well-being is our
                        priority.</p>
                </div>
            </section>
            <section className="eap-introduction">
                <div className="eap-introduction-body">
                    <div className="eap-introduction-body-content">
                        <h2>Welcome to Our Employee Assistance Program (EAP)</h2>
                        <p>At White Flag Coaching, your well-being is our top priority. Our Employee Assistance Program
                            (EAP) offers a range of confidential services designed to support you through life's
                            challenges.
                            Whether you're seeking counselling for personal issues, guidance on work-life balance, or
                            resources for wellness, our dedicated team is here to help you every step of the way.</p>
                        <p>Our EAP services also include crisis intervention and referrals to external specialists when
                            needed. We understand the importance of addressing both personal and professional obstacles
                            to
                            ensure you can thrive in all aspects of your life. Explore our offerings and discover how we
                            can
                            assist you in achieving a healthier, more fulfilling future.</p>
                        <p className="signature">Be, Empowered.</p>
                        <div className="eap-introduction-body-content-logo"></div>
                    </div>
                </div>
            </section>
            <section className="eap-testimonials">
                <div className="eap-testimonials-body">
                    <div className="eap-testimonials-body-content">
                        <EAPTestimonial />
                    </div>
                </div>
            </section>
            <section className="eap-values">
                {
                    eapValues.map((value) => {
                        return <div className={value.className}>
                            <iframe className="eap-values-video"
                                width="470"
                                height="300"
                                src={value.link}
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowfullscreen></iframe>
                            </div>
                    })
                }
            </section>
            <section className="eap-gallery">
                <div className="eap-gallery-body">
                    <Gallery />
                </div>
            </section>
        </div>
    );
};

export default EapPage;
