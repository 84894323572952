// src/utils/logger.jsx

class Logger {
    constructor() {
        this.isDevelopment = process.env.NODE_ENV === 'development';
        this.logLevel = process.env.REACT_APP_LOG_LEVEL || 'info';

        // Log levels hierarchy
        this.levels = {
            error: 0,
            warn: 1,
            info: 2,
            debug: 3
        };
    }

    shouldLog(level) {
        return this.levels[level] <= this.levels[this.logLevel];
    }

    formatMessage(level, message, data) {
        const timestamp = new Date().toISOString();
        const formattedData = data ? JSON.stringify(data, this.sanitizeData) : '';
        return `[${timestamp}] ${level.toUpperCase()}: ${message} ${formattedData}`;
    }

    // Sanitize sensitive data
    sanitizeData(key, value) {
        const sensitiveFields = ['password', 'token', 'secret', 'authorization'];
        if (sensitiveFields.includes(key.toLowerCase())) {
            return '[REDACTED]';
        }
        return value;
    }

    // Save logs to localStorage for debugging
    saveLog(level, message, data) {
        try {
            const logs = JSON.parse(localStorage.getItem('app_logs') || '[]');
            logs.push({
                timestamp: new Date().toISOString(),
                level,
                message,
                data: data ? JSON.stringify(data) : undefined
            });

            // Keep only last 100 logs
            while (logs.length > 100) logs.shift();

            localStorage.setItem('app_logs', JSON.stringify(logs));
        } catch (error) {
            console.error('Error saving log to localStorage:', error);
        }
    }

    error(message, data = null) {
        const formattedMessage = this.formatMessage('error', message, data);
        console.error(formattedMessage);
        this.saveLog('error', message, data);

        // Send to error reporting service in production
        if (!this.isDevelopment) {
            // Here you could integrate with error reporting services
            // like Sentry, LogRocket, etc.
        }
    }

    warn(message, data = null) {
        if (this.shouldLog('warn')) {
            const formattedMessage = this.formatMessage('warn', message, data);
            console.warn(formattedMessage);
            this.saveLog('warn', message, data);
        }
    }

    info(message, data = null) {
        if (this.shouldLog('info')) {
            const formattedMessage = this.formatMessage('info', message, data);
            console.log(formattedMessage);
            this.saveLog('info', message, data);
        }
    }

    debug(message, data = null) {
        if (this.shouldLog('debug')) {
            const formattedMessage = this.formatMessage('debug', message, data);
            console.log(formattedMessage);
            this.saveLog('debug', message, data);
        }
    }

    // Get logs for debugging
    getLogs() {
        try {
            return JSON.parse(localStorage.getItem('app_logs') || '[]');
        } catch (error) {
            console.error('Error retrieving logs:', error);
            return [];
        }
    }

    // Clear logs
    clearLogs() {
        localStorage.removeItem('app_logs');
    }
}

// Create a singleton instance
const logger = new Logger();
export default logger;