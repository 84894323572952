import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { 
    faPhone,
    faEnvelopeOpen,
} from '@fortawesome/free-solid-svg-icons';

import {
    faFacebookF,
    faLinkedin,
    faTwitter,
    faInstagram,
    faTiktok
} from '@fortawesome/free-brands-svg-icons';

import './Footer.css'

const Footer = () => {
    return (
        <footer className="footer-section">
            <div className="container">
                <div className="footer-cta pt-5 pb-5">
                    <div className="row">
                        <div className="col-xl-4 col-md-4 mb-30">
                            <div className="single-cta">
                                <FontAwesomeIcon icon={faPhone} />
                                <div className="cta-text">
                                    <h4>Call us</h4>
                                    <span>072 853 6406</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4 mb-30">
                            <div className="single-cta">
                                <FontAwesomeIcon icon={faEnvelopeOpen} />
                                <div className="cta-text">
                                    <h4>Mail us</h4>
                                    <span>
                                        <Link to="mailto:info@whiteflagcoaching.co.za" rel="noopener noreferrer" target="_blank">info@whiteflagcoaching.co.za</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-content pt-5 pb-5">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 mb-50">
                            <div className="footer-widget">
                                <div className="footer-logo">
                                    <Link to="/"><img src="/Assets/Images/White_Flag_Logo-01%5B1%5D.svg" className="img-fluid" alt="logo"/></Link>
                                </div>
                                <div className="footer-text">
                                    <p>White Flag Coaching. A safe space, where you can find acceptance to express yourself and be heard. An environment that is built on trust, belief and a curiosity that promotes growth ...</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                            <div className="footer-widget">
                                <div className="footer-widget-heading">
                                    <h3>Useful Links</h3>
                                </div>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                    <li><Link to="/about-us">About us</Link></li>
                                    <li><Link to="/faq">Faq</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                            <div className="footer-social-icon">
                                <span>Follow us</span>
                                <Link to="https://www.facebook.com/whiteflagcoaching" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faFacebookF} />
                                </Link>
                                <Link to="https://www.linkedin.com/in/white-flag-coaching-npc-b33b291b3/?originalSubdomain=za"
                                   target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faLinkedin} />
                                </Link>
                                <Link to="https://twitter.com/whiteflagcoach" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faTwitter} />
                                </Link>
                                <Link to="https://www.instagram.com/whiteflag_coaching/" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </Link>
                                <Link to="https://www.tiktok.com/@whiteflagcoaching" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faTiktok} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                            <div className="copyright-text">
                                <p>Copyright &copy; 2023, ll Right Reserved <Link to="/">White
                                    Flag Coaching</Link></p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                            <div className="footer-menu">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/about-us">About</Link></li>
                                    <li><Link to="/contact-us">Contact</Link></li>
                                    <li><Link to="/faq">Faq</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;