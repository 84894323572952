// src/components/Modal/Modal.jsx
import React, { useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTimes
} from '@fortawesome/free-solid-svg-icons';
// import {
//     faWhatsapp,
//     faFacebookF,
//     faLinkedin,
//     faTwitter,
//     faInstagram,
//     faTiktok
// } from '@fortawesome/free-brands-svg-icons';

import './Modal.css';
import ContactUs from "../../Pages/MainPages/ContactUs/ContactUs";

const Modal = ({ showModal, closeModal, selectedItems }) => {
    // Add console log to verify props
    console.log('Modal Props:', { showModal, selectedItems });

    useEffect(() => {
        const handleEscKey = (event) => {
            if (event.key === "Escape") {
                closeModal();
            }
        };

        if (showModal) {
            document.addEventListener('keydown', handleEscKey);
            document.body.style.overflow = 'hidden';
        }

        return () => {
            document.removeEventListener('keydown', handleEscKey);
            document.body.style.overflow = 'unset';
        };
    }, [showModal, closeModal]);

    if (showModal === false) return null;

    return (
        <div className="modal-container">
            <div 
                className="modal-backdrop"
                onClick={closeModal}
                aria-hidden="true"
            />
            
            <div className="modal-content">
                <button 
                    className="modal-close-btn"
                    onClick={closeModal}
                    aria-label="Close modal"
                >
                 <FontAwesomeIcon icon={faTimes} />
                </button>
                
                <ContactUs selectedItems={selectedItems} />
            </div>
        </div>
    );
};

export default Modal;