import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";

import PageHeading from "../../../Components/PageHeading/PageHeading";
import './GroupsPage.css'
import Checklist from "../../../Components/Checklist/Checklist";

const GroupsPage = () => {
    const location = useLocation();

    useEffect(() => {
        // Scroll logic here, triggered on component mount or when the location changes
        const { hash } = location;
        const element = document.getElementById(hash.substring(1));
        if (hash) {
            if (element) {
                element.scrollIntoView({ behavior: 'smooth',  });
            }
        }
    }, [location]);

    let pageDetails = {
        imgClass: "groupsheading-bg-img",
        heading: "Welcome to White Flag Team Coaching!",
        bgColors: {
            // Blue and Yellow - Personal Coaching
            // primary: "#EED570",
            // secondary: "#377ed4"

            // Purple and Pink - Relationships
            // primary: "#F57697",
            // secondary: "#7651e1"

            // Orange and Green - Groups
            primary: "#F68351",
            // primary: "#4BB8A9",
            // secondary: "#377c70"
            secondary: "#4BB8A9"
        },
        dropshadow: "group-dropshadow",
        paragraphs: {
            p1: "Building stronger teams is our passion!",
            p2: "We know a team's success hinges on a strong connection between members.  Whether work colleagues, a sports team, or a friend group, we can help.",
            p3: "Think of us as your team's personal trainers.  With years of experience, we identify areas for improvement and guide your team towards winning results. Let's elevate your team! (On-site coaching available)"
        },
        list_content: {}
    }

    const checklist_items = [
        {
            heading: "Motivation",
            content: "Team is struggling to stay motivated for the task at hand or cause of the institution. Team morale is low."
        },
        {
            heading: "Communication",
            content: "Lack of effective communication can hinder or even destruct a team from progressing."
        },
        {
            heading: "Emotional intelligence and mental wellness",
            content: "Not everyone has been skilled or equipped in these areas. Raising awareness of emotional intelligence and mental wellness can improve empathy and compassion among team members."
        },
        {
            heading: "Calming, peace, mindfulness session",
            content: "Unwinding talks or meditation sessions can improve team productivity and mindset."
        },
        {
            heading: "Office or workplace etiquette",
            content: "Developing a system of workplace etiquette that works for everyone can improve team dynamics."
        },
        {
            heading: "Topic driven workshop",
            content: "Workshops can address specific team needs and help with growth and development."
        },
        {
            heading: "Debrief",
            content: "Providing a safe and confidential space for team members to vent and voice concerns can improve team confidence."
        },
        {
            heading: "Other",
            content: "Please elaborate on your specific team needs."
        }
    ];

    return (
        <React.Fragment>
            <PageHeading PageInfo={pageDetails}>
                <h4 className={"w-100 mx-auto"} style={{marginBottom: "-30px", marginTop: "20px", color: "white"}}>
                    Choose from the list below in order for us to have an idea of what your coaching journey would need to focused on. On receipt of your symptoms and conditions that is sent via email to us we would set up a free intro call (15min ) to discuss the way forward . Lets go!
                </h4>
                <Checklist ChecklistItems={checklist_items} Heading={"Symptoms and conditions you may be experiencing"}/>
            </PageHeading>
        </React.Fragment>
    );
}

export default GroupsPage;