import React, { useState, useEffect } from "react";
import './Checklist.css'
import Modal from "../Modal/Modal";

const Checklist = ({ ChecklistItems, Heading }) => {
    const [selectedItems, setSelectedItems] = useState([]);
    
    const handleCheckboxChange = (event) => {
        const checked= event.target;
        let instructionText = ""

        if (checked) {
            // Get the closest instruction element
            const headingElement = event.target.closest(".checklist-activity").querySelector(".heading");
            const contentElement = event.target.closest(".checklist-activity").querySelector(".content");

            // Extract the text content
            const instructionHeading = headingElement.textContent.trim();
            const instructionContent = contentElement.textContent.trim();

            instructionText = `${instructionHeading} - ${instructionContent}`

            // Add the text to the selectedItems list
            setSelectedItems([...selectedItems, instructionText]);
        } else {
            setSelectedItems(selectedItems.filter((item) => item !== instructionText));
        }
    };

    useEffect(() => {
        console.log('Selected items updated:', {
            items: selectedItems,
            count: selectedItems.length
        });
    }, [selectedItems]);

    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        if (selectedItems.length > 0) {
            console.log('Opening modal with selected items:', selectedItems);
            setModalOpen(true);
        }
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <React.Fragment>
            <section className="checklist-section">
                <h1 className="checklist-h1 w-100 mx-auto">{Heading}</h1>
                <ul className="checklist-list w-100 mx-auto">
                    {ChecklistItems.map((item, index) => (
                        <li key={index} className="checklist-activity">
                            <label>
                                <input 
                                    className="item" 
                                    type="checkbox" 
                                    onChange={handleCheckboxChange}
                                />
                                <div className="check-container">
                                    <div className="check">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            version="1.1"
                                            id="Capa_1"
                                            x="0px"
                                            y="0px"
                                            width="28px"
                                            height="28px"
                                            viewBox="0 0 363.025 363.024"
                                        >
                                            <g>
                                                <polygon
                                                    points="147.957,258.935 83.068,194.046 91.348,185.767 147.957,242.375 271.171,119.166 279.451,127.445"
                                                    fill="#FFF"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="shade"></div>
                                    <div className="instruction">
                                        <strong id={`heading-${index}`} className="heading">
                                            {item.heading}
                                        </strong>{" "}
                                        <span id={`content-${index}`} className="content">
                                            {item.content}
                                        </span>
                                    </div>
                                </div>
                            </label>
                        </li>
                    ))}
                </ul>
                <div className="col-xs-12 my-4">
                    <div 
                        onClick={openModal} 
                        className={`btn-lrg submit-btn ${selectedItems.length === 0 ? 'disabled' : ''}`}
                    >
                        Reach Out
                    </div>
                </div>
            </section>
            
            {isModalOpen && (
                <Modal 
                    showModal={isModalOpen} 
                    selectedItems={selectedItems} 
                    closeModal={closeModal}
                />
            )}
        </React.Fragment>
    );
};

export default Checklist;