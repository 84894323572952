import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import './styles/App.css';
import './styles/fonts/fonts.css'

import ScrollToTop from './Components/ScrollToTop/ScrollToTop'
import { FontLoader } from './utils/FontLoader';

import Navbar from "./Components/Navbar/Navbar";
import SocialMediaNavbar from "./Components/SocialMediaNavbar/SocialMediaNavbar";
import Footer from "./Components/Footer/Footer";
import NotFound from "./Pages/NotFound/NotFound";
import Homepage from "./Pages/MainPages/Home/Homepage";
import AboutPage from "./Pages/MainPages/AboutPage/AboutPage";
import ContactUs from "./Pages/MainPages/ContactUs/ContactUs";
import IndividualPage from "./Pages/OfferingPages/Individual/IndividualPage";
import RelationshipsPage from "./Pages/OfferingPages/Relationships/RelationshipsPage";
import GroupsPage from "./Pages/OfferingPages/Groups/GroupsPage";
import FAQ from "./Pages/MainPages/FAQ/FAQ";
import EapPage from "./Pages/OfferingPages/Businesses/EapPage";
import dotenv from "dotenv"
import PrivacyPolicy from "./Pages/MainPages/PrivacyPolicy/PrivacyPolicy";

// Router wrapper component to use ScrollToTop
const RouterWrapper = () => {
  return (
    <div className="page-wrapper-max">
      <ScrollToTop />
      <Navbar />
      <div className="page-wrapper">
        <div className="main-content">
          <div className="content">
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/about-us" element={<AboutPage />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/personal-coaching" element={<IndividualPage />} />
              <Route path="/relationships" element={<RelationshipsPage />} />
              <Route path="/groups" element={<GroupsPage />} />
              <Route path="/eap-services" element={<EapPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <SocialMediaNavbar className="hide-socials show-socials-nav" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

dotenv.config({
  path: ".env"
});

const App = () => {
  useEffect(() => {
    document.documentElement.classList.add('fonts-loading');
    FontLoader.load().then(() => {
      document.documentElement.classList.remove('fonts-loading');
    });
  }, []);

  return (
    <BrowserRouter>
      <RouterWrapper />
    </BrowserRouter>
  );
}

export default App;