import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <div className="privacy-banner">
                <div className="banner-content">
                    <h1>Privacy Policy</h1>
                    <p>Your privacy is our priority</p>
                </div>
            </div>

            <div className="privacy-policy-content">
                <div className="policy-header">
                    <div className="header-info">
                        <div className="version-info">
                            <span>Version 1.0</span>
                            <span className="separator">|</span>
                            <span>Effective Date: December 3, 2024</span>
                        </div>
                        <div className="policy-intro">
                            <p>
                                This Privacy Policy describes how White Flag Coaching ("White Flag," "we," "our," or "us")
                                collects, uses, and protects your personal information. We are committed to ensuring
                                the privacy and security of your data while providing high-quality coaching services.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="quick-links">
                    <h2>Quick Links</h2>
                    <div className="links-grid">
                        <a href="#collection">Information Collection</a>
                        <a href="#use">Information Usage</a>
                        <a href="#sharing">Information Sharing</a>
                        <a href="#security">Data Security</a>
                        <a href="#rights">Your Rights</a>
                        <a href="#contact">Contact Us</a>
                    </div>
                </div>

                <div className="policy-sections">
                    <section id="collection">
                        <h2>1. Information Collection</h2>
                        <div className="section-content">
                            <h3>1.1 Personal Information</h3>
                            <p>In the course of providing our coaching services, we collect:</p>
                            <ul>
                                <li>
                                    <strong>Identity Information:</strong>
                                    Full name, date of birth, and identification details
                                </li>
                                <li>
                                    <strong>Contact Information:</strong>
                                    Email address, phone number, and physical address
                                </li>
                                <li>
                                    <strong>Professional Information:</strong>
                                    Employment details and professional background when relevant
                                </li>
                                <li>
                                    <strong>Session Information:</strong>
                                    Notes and records from coaching sessions
                                </li>
                            </ul>

                            <h3>1.2 Technical Information</h3>
                            <p>When you use our website or digital services, we automatically collect:</p>
                            <ul>
                                <li>
                                    <strong>Device Information:</strong>
                                    Browser type, operating system, and device identifiers
                                </li>
                                <li>
                                    <strong>Usage Data:</strong>
                                    IP address, access times, and pages viewed
                                </li>
                                <li>
                                    <strong>Communication Data:</strong>
                                    Preferences and interaction with our digital services
                                </li>
                            </ul>
                        </div>
                    </section>

                    <section id="use">
                        <h2>2. Information Usage</h2>
                        <div className="section-content">
                            <div className="usage-grid">
                                <div className="usage-item">
                                    <h4>Primary Purposes</h4>
                                    <ul>
                                        <li>Delivering coaching services</li>
                                        <li>Managing appointments</li>
                                        <li>Processing payments</li>
                                        <li>Communicating updates</li>
                                    </ul>
                                </div>
                                <div className="usage-item">
                                    <h4>Service Improvement</h4>
                                    <ul>
                                        <li>Analyzing service usage</li>
                                        <li>Enhancing user experience</li>
                                        <li>Developing new features</li>
                                        <li>Quality assurance</li>
                                    </ul>
                                </div>
                                <div className="usage-item">
                                    <h4>Legal Compliance</h4>
                                    <ul>
                                        <li>Regulatory requirements</li>
                                        <li>Legal obligations</li>
                                        <li>Contract fulfillment</li>
                                        <li>Dispute resolution</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="security">
                        <h2>3. Data Security</h2>
                        <div className="section-content">
                            <div className="security-measures">
                                <p>
                                    We implement comprehensive security measures to protect your information, including:
                                </p>
                                <ul>
                                    <li>Encryption of data in transit and at rest</li>
                                    <li>Regular security assessments and audits</li>
                                    <li>Strict access controls and authentication</li>
                                    <li>Employee training on data protection</li>
                                    <li>Incident response procedures</li>
                                </ul>
                            </div>
                        </div>
                    </section>

                    <section id="rights">
                        <h2>4. Your Rights</h2>
                        <div className="section-content">
                            <div className="rights-grid">
                                <div className="right-item">
                                    <h4>Access</h4>
                                    <p>Request a copy of your personal data</p>
                                </div>
                                <div className="right-item">
                                    <h4>Rectification</h4>
                                    <p>Correct inaccurate information</p>
                                </div>
                                <div className="right-item">
                                    <h4>Erasure</h4>
                                    <p>Request deletion of your data</p>
                                </div>
                                <div className="right-item">
                                    <h4>Restriction</h4>
                                    <p>Limit how we use your data</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="contact" className="contact-section">
                        <h2>5. Contact Information</h2>
                        <div className="section-content">
                            <div className="contact-grid">
                                <div className="contact-item">
                                    <h4>General Inquiries</h4>
                                    <p>info@whiteflagcoaching.co.za</p>
                                    <p>072 853 6406</p>
                                </div>
                                <div className="contact-item">
                                    <h4>Data Protection Officer</h4>
                                    <p>privacy@whiteflagcoaching.co.za</p>
                                </div>
                                <div className="contact-item">
                                    <h4>Office Hours</h4>
                                    <p>Monday - Friday</p>
                                    <p>9:00 AM - 5:00 PM SAST</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <footer className="policy-footer">
                    <p>© 2024 White Flag Coaching. All rights reserved.</p>
                    <p>This policy was last updated on December 3, 2024</p>
                </footer>
            </div>
        </div>
    );
};

export default PrivacyPolicy;