import React from "react";
import {Link} from "react-router-dom";
import './DynamicCards.css'

const DynamicCards = () => {

    const imgRelativePath = `${process.env.PUBLIC_URL}/Assets/Images`
    const service_groups = [
        {
            heading: "Personal Coaching - One on One",
            subheading: "Hi there!  Welcome to your personal growth journey with White Flag Coaching. This is a powerful step you're taking, and we applaud your courage!",
            flag: imgRelativePath + "/DynamicCards/individual.jpg",
            url: "/personal-coaching"
        },
        {
            heading: "Relationships - For You And Your Partner",
            subheading: "We understand seeking help for a struggling relationship is tough. But you're brave to take this step! Relationships, romantic, family, friendships - they all need work sometimes. That's where we come in.",
            flag: imgRelativePath + "/DynamicCards/relationships.jpg",
            url: "/relationships"
        },
        {
            heading: "Groups - Group Coaching Programs",
            subheading: "Building stronger teams is our passion! We know a team's success hinges on a strong connection between members.  Whether work colleagues, a sports team, or a friend group, we can help.",
            flag: imgRelativePath + "/DynamicCards/groups.jpg",
            url: "/groups"
        },
        {
            heading: "Businesses - Workforce Business EAP Solutions",
            subheading: "We understand that your staff is your most important asset. We offer professional NLP coaching and consultation for emotional and mental wellness.",
            flag: imgRelativePath + "/groups.jpg",
            url: "/eap-services"
        }
    ]

    return (
        <React.Fragment>
            <div className="main mx-auto">
                <div className="dynamic">
                    <div className="countries-image"
                         style={{backgroundImage: "url(" + service_groups[0].flag.trim() + ")"}}></div>
                    <div className="countries-overlay">
                        <h6>{service_groups[0].heading}</h6>
                        <p>{service_groups[0].subheading}</p>
                        <div className="col-xs-12">
                            <Link to={`${service_groups[0].url}`} className="btn-lrg explore-btn ">Explore</Link>
                        </div>
                    </div>
                </div>
                <div className="dynamic">
                    <div className="countries-image"
                         style={{backgroundImage: "url(" + service_groups[1].flag.trim() + ")"}}></div>
                    <div className="countries-overlay">
                        <h6>{service_groups[1].heading}</h6>
                        <p>{service_groups[1].subheading}</p>
                        <div className="col-xs-12">
                            <Link to={`${service_groups[1].url}`} className="btn-lrg explore-btn">Explore</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main mx-auto">
                <div className="dynamic">
                    <div className="countries-image"
                         style={{backgroundImage: "url(" + service_groups[2].flag.trim() + ")"}}></div>
                    <div className="countries-overlay">
                        <h6>{service_groups[2].heading}</h6>
                        <p>{service_groups[2].subheading}</p>
                        <div className="col-xs-12">
                            <Link to={`${service_groups[2].url}`} className="btn-lrg explore-btn">Explore</Link>
                        </div>
                    </div>
                </div>
                <div className="dynamic">
                    <div className="countries-image"
                         style={{backgroundImage: "url(" + service_groups[3].flag.trim() + ")"}}></div>
                    <div className="countries-overlay">
                        <h6>{service_groups[3].heading}</h6>
                        <p>{service_groups[3].subheading}</p>
                        <div className="col-xs-12">
                            <Link to={`${service_groups[3].url}`} className="btn-lrg explore-btn">Explore</Link>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DynamicCards;